// https://www.highcharts.com/demo/bar-stacked
const bubbleChart = () => {
const infos = document.getElementById("infos-dataviz")
  if (infos) {

    let xaxisCategories = infos.dataset.xaxisCategories
    xaxisCategories = JSON.parse("[" + xaxisCategories + "]")[0];
    let series_data = infos.dataset.series
    series_data = JSON.parse("[" + series_data + "]");
    const titleChart = infos.dataset.titleChart
    let colorsCol = JSON.parse(infos.dataset.colorsCol);


    chartBubble({
      'titleChart': titleChart,
      'series_data': series_data,
      'colorsCol': colorsCol
    });

  }
}

const  chartBubble = (params) => {
  const listValue = params.series_data[0][0]['data'].flat().filter(element => typeof(element) != typeof('str'))
  const maxValue = Math.max(...listValue)
  const minValue = Math.min(...listValue)

  Highcharts.chart('container', {
      chart: {
          type: 'packedbubble',
          style: {fontFamily: "'Marianne', sans-serif"},

      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ["viewFullscreen", "printChart"]
          }
        }
      },
      colors: params.colorsCol,
      legend: {
          verticalAlign: 'top',
          enabled: true,
          layout: "horizontal",
          // reversed: true,
          title: {
            style: {"fontWeight": "bolder",
            "fontSize": "18"},
            text: ``
          }
        },
      title: {
          text: `${params.titleChart}`
      },
      tooltip: {
          useHTML: true,
          pointFormat: '<b>{point.name}:</b> {point.value} €<sub></sub>'
      },
      plotOptions: {
          packedbubble: {
              minSize: '5%',
              maxSize: '100%',
              // zMin: minValue,
              // zMax: maxValue,
              layoutAlgorithm: {
                  gravitationalConstant: 0.05,
                  splitSeries: true,
                  seriesInteraction: true,
                  dragBetweenSeries: false,
                  parentNodeLimit: true
              },
              dataLabels: {
                  enabled: true,
                  format: '{point.name}',
                  filter: {
                      property: 'y',
                      operator: '>',
                      value: maxValue / 10
                  },
                  style: {
                      color: 'black',
                      textOutline: 'none',
                      fontWeight: 'normal'
                  }
              }
          }
      },
      series: params.series_data[0]
  });

}


export {bubbleChart }
