
const btnSelectedCategory = () => {
  const btns = document.querySelectorAll('.btn-data-views')

  setBtnSelectedOptionListener(btns)
  if (btns.length > 0) {
    btns.forEach((btn) => {
      btn.addEventListener('click',(event) => {
        removeBtnActive(btns);
        event.currentTarget.classList.toggle('btn-active');

        if (event.currentTarget.classList.contains('btn-active')) {
          manageSetAttribute(event.currentTarget, "true");
        }
        setBtnSelectedOption(btns);
      })
    })
  }
}
const setBtnSelectedOptionListener = (btns) => {
  const btnSelectedOption = document.getElementById('btn-selected-option')
  if (btnSelectedOption) {
    btnSelectedOption.addEventListener('click', (event) => {
      btns.forEach((btn) => {
        if (btn.classList.contains('btn-active')) {
          btnSelectedOption.dataset.target = `idCat${btn.dataset.idCat}`
          btnSelectedOption.attributes['aria-controls'].value = `fr-modal-${btn.dataset.idCat}`
        }
      })
    })
  }
}
const setBtnSelectedOption = (btns) => {
  const btnSelectedOption = document.getElementById('btn-selected-option')
  if (btnSelectedOption) {
    btns.forEach((btn) => {
      if (btn.classList.contains('btn-active')) {
        btnSelectedOption.dataset.target = `idCat${btn.dataset.idCat}`
        btnSelectedOption.attributes['aria-controls'].value = `fr-modal-${btn.dataset.idCat}`
        // $(`#${btnSelectedOption.dataset.target}`).modal('show')

      }
    })
  }
}
const removeBtnActive = (btns) => {
  btns.forEach((btn) => {
    btn.classList.remove('btn-active');
    manageSetAttribute(btn, "false")

  })
}

const manageSetAttribute = (element, true_false) => {
  element.setAttribute("aria-current", true_false);
  element.setAttribute("aria-pressed", true_false)


}


export { btnSelectedCategory }
