// https://stevepolito.design/blog/create-a-nested-form-in-rails-from-scratch/
import { addFields } from "./addFields.js"

import { removeFields } from "./removeFields.js"
const nestedForm = () => {
  new addFields();
  new removeFields();
}

export { nestedForm }
