import "bootstrap";
import Highcharts from 'highcharts';

// Alternatively, this is how to load Highcharts Stock. The Maps and Gantt
// packages are similar.
// import Highcharts from 'highcharts/highstock';
import {designChart } from '../dataView/index.js';
import { nestedForm } from '../dataView/form/nestedForms/nestedForm.js';
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
// Initialize exporting module.
Exporting(Highcharts);
require("highcharts/highcharts-more")(Highcharts);


document.addEventListener('turbolinks:load', () => {

  window.Highcharts = Highcharts
  const donwload = "Télécharger en"

  Highcharts.setOptions({
    lang: {
      downloadCSV:`${donwload} CSV`,
      downloadJPEG:`${donwload} JPEG image`,
      downloadPDF:`${donwload} PDF document`,
      downloadPNG:`${donwload} PNG image`,
      downloadSVG:`${donwload} SVG vector image`,
      downloadXLS:`${donwload} XLS`,
      viewFullscreen:'Voir en plein écran',
      printChart:'Imprimer le graphique',
      drillUpText:undefined,
      exitFullscreen:'Quitter le plein écran',
      }
    });
  designChart();

  nestedForm();
});

// series: [{
//           name: 'Year 1800',
//           data: [107, 31, 635, 203, 2]
//       }, {
//           name: 'Year 1900',
//           data: [133, 156, 947, 408, 6]
//       }, {
//           name: 'Year 2000',
//           data: [814, 841, 3714, 727, 31]
//       }, {
//           name: 'Year 2016',
//           data: [1216, 1001, 4436, 738, 40]
//       }]
