// https://www.highcharts.com/demo/bar-stacked
const lineChart = () => {
const infos = document.getElementById("infos-dataviz")
  if (infos) {
    let xaxisCategories = infos.dataset.xaxisCategories
    xaxisCategories = JSON.parse("[" + xaxisCategories + "]")[0];
    let series_data = infos.dataset.series
    series_data = JSON.parse("[" + series_data + "]");
    const titleChart = infos.dataset.titleChart
    let colorsCol = JSON.parse(infos.dataset.colorsCol);

    Highcharts.chart('container', {
        chart: {
            type: 'bar',
            style: {fontFamily: "'Marianne', sans-serif"},
            ignoreHiddenSeries: false,
            // scrollablePlotArea: {
            //   minHeight: '90vh',
            //   opacity:0.85,
            //   scrollPositionY: 0
            // }
        },
        colors: colorsCol,
        title: {
            text: titleChart
        },
        xAxis: [{
            max: 18,
            categories: xaxisCategories
        }],
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            enabled: true,
            layout: "vertical",
            reversed: false,
            title: {
              style: {"fontWeight": "bolder",
              "fontSize": "18"},
              text: `Répartition par`
            },
        },

        plotOptions: {
            column: {
                stacking: '',
                pointWidth: 5,
                minPointLength: 0,
            },
            funnel: {
              height: '10%',
            },
            series: {
              borderRadius: 2,
            },
        },
        series: series_data[0]
    });


  }
}

// {
//   name: 'investissement',
//   data: [30, 40, 4, 2, 5,4]
// }
export {lineChart }
