import {  lineChart } from './templateChart/lineChart.js';
import {  barChart } from './templateChart/barChart.js';
import { barPercentageChart } from './templateChart/barPercentageChart.js';
import { bubbleChart } from './templateChart/bubbleChart.js';
import { btnSelectedCategory } from './buttons/category.js';
const designChart = () => {
  btnSelectedCategory();

  const infos = document.getElementById("infos-dataviz")

  if (infos) {

    if (infos.dataset.template == "hist") {
      barChart();

    } else if (infos.dataset.template == 'bar_cum') {
      barPercentageChart();

    }
    else if (infos.dataset.template == 'line') {
       lineChart();
    } else if (infos.dataset.template == 'bubble') {
      bubbleChart();
    }
    // barPercentageChart();
    //  lineChart();

    eventListener({
      'paramsAndId': 'dataset'
    });
    eventListener({
      'paramsAndId': 'ordering-col'
    });
    eventListener({
      'paramsAndId': 'increase-col'
    });
  }


  const btnToRadioBtns = document.querySelectorAll('.cta-radio-button-dataset')
  btnToRadioBtns.forEach((btnToRadioBtn) => {
    if (btnToRadioBtn) {
      btnToRadioBtn.addEventListener('click', (event) => {
        // const radioBtns = document.querySelectorAll('#radio-button-dataset input');
        const radioBtnChecked = document.querySelectorAll('#radio-button-dataset input:checked')[0]

        window.location = window.location.origin + window.location.pathname + "?" + `dataset=` + radioBtnChecked.value + "#anchor-chart";

        // radioBtns.forEach((input) => {
        //   if (input.checked) {
        //     console.log(input.value)
        //     window.location = window.location.origin + window.location.pathname + "?" + `dataset=` + input.value + "#anchor-chart"
        //   }
        // })
      })
    }
  })

        // const radioIncreases = document.querySelectorAll('#radio-button-increase input')
        // radioIncreases.forEach((radioIncrease) => {

        //   radioIncrease
        // })
}

const eventListener = (params) => {
  const btnDataset = document.getElementById(params.paramsAndId)

  if (btnDataset) {
    btnDataset.addEventListener('change', (event) => {
      let url = window.location.origin + window.location.pathname
      if (params.paramsAndId == 'ordering-col') {
        window.location = url + "?" + buildUrlWithOrderingCol(params) + "&" + buildUrlWithRadioButton(params)

      } else if (params.paramsAndId == 'increase-col') {

        window.location = url + "?" + buildUrlWithRadioButton(params) + "&" + buildUrlWithOrderingCol(params)

      } else {
        window.location = window.location.origin + window.location.pathname + "?" + `${params.paramsAndId}=` + event.currentTarget.value + "#anchor-chart"
      }
    })
  }
}


const buildUrlWithOrderingCol = (params) => {
  if (document.getElementById('dataset')) {
    const datasetValue = document.getElementById('dataset').value
  }
  const formAutoComplete = document.getElementById('autocomplete-form')
  const formAutoCompleteInputs = formAutoComplete.querySelectorAll('input')
  let paramsList = []
  formAutoCompleteInputs.forEach((input) => {
    const key = input.name
    const value = input.value
    paramsList.push(`${key}=${value}`)
  })
  const eventCurrentTarget = document.querySelector('#ordering-col option:checked').value
  const parameters = `ordering-col=` + eventCurrentTarget +  `&${paramsList.join('&')}`
  // const url = window.location.origin + window.location.pathname + "?" + `${params.paramsAndId}=` + event.currentTarget.value +  `&${paramsList.join('&')}` + "#anchor-chart"
  return parameters
}

const buildUrlWithRadioButton = (params) => {

  const inputIncreaseDecrease = document.querySelector('#increase-col option:checked')
  let paramsUpOrDown = ''


  if (inputIncreaseDecrease.id == 'radio-inline-up') {
    paramsUpOrDown = 'up'
  } else {
    paramsUpOrDown = 'down'
  }
  const parameters = 'upOrDown=' + paramsUpOrDown
  return parameters
}
export { designChart }



