// https://www.highcharts.com/demo/column-stacked-percent

const barPercentageChart = () => {
  const infos = document.getElementById("infos-dataviz")
  if (infos) {
    // let xaxisCategories = infos.dataset.xaxisCategories
    // xaxisCategories = JSON.parse("[" + xaxisCategories + "]")[0];

    let series_data = infos.dataset.series
    series_data = JSON.parse("[" + series_data + "]");


    const titleChart = infos.dataset.titleChart

    let colorsCol = JSON.parse(infos.dataset.colorsCol);

    Highcharts.chart('container', {
        chart: {
            type: 'column',
            style: {fontFamily: "'Marianne', sans-serif"},
            // inverted: true,
            // scrollablePlotArea: {
            //   minHeight: '90vh',
            //   opacity:0.85
            // }
        },
        legend: {
          enabled: true,
          layout: "vertical",
          reversed: true,
          title: {
            style: {"fontWeight": "bolder",
            "fontSize": "18"},
            text: ``
          }
        },
        // colors: colorsCol.reverse(),
        colors: colorsCol,
        title: {
            text: titleChart,
        },
        xAxis: [{
            max: 18,
            type: 'category',
            // categories: 'xaxisCategories',
            showEmpty: false,

            labels: {
              format: '{text}',
              formatter: function () {
                if (typeof(this.value) == typeof(10)) {
                  return `${this.value} - Comparer `
                } else {
                  return this.value
                }
              }
            }
        }],
        yAxis: {
            min: 0,
            max: 99,
            title: {
                text: 'Répartition en pourcentage'
            },
            labels: {
                format: '{value:.1f}%'
            },
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
            shared: true,
        },
        plotOptions: {
            column: {
                stacking: 'percent',
                pointWidth: 10,
                minPointLength: 0,
                dataSorting:{
                  enabled:true,
                  matchByName:false,
                  sortKey:'x'
                },
            },
            funnel: {
              height: '10%',
            },
            series: {
              borderRadius: 5,
            }
        },
        series: series_data[0]
    });

  }
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export { barPercentageChart }
